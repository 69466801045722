<template>
  <div>
    <div id="edit-config" v-on:click.stop="showSettings()">
      <SettingsIcon class="clickable" />
    </div>

    <!-- Add the modal -->
    <div class="modal" v-show="this.showConfig" v-on:click="hideSettings()">
      <div class="modal-container" v-on:click.stop="">
        <h1>Edit your settings</h1>
        <form id="config-form" action="#" method="GET">
          <div>
            <label for="input-page-title">Page Title</label>
            <input
              type="text"
              ref="title"
              placeholder="Page title"
            >
          </div>
          <div>
            <label for="input-page-background">Page Background (URL)</label>
            <input
              type="text"
              ref="background"
              placeholder="https://source.unsplash.com/random"
            >
          </div>
          <div>
            <p>
              <strong>GDPR notice</strong>: This web application is being hosted
              on a server in Germany, European Union. Due to this, this website
              falls under the GDPR (General Data Protection Regulation). We
              hereby inform you of our data storing procedures.
            </p>
            <p>
              None of your data is being stored on our server. All settings that
              you configure on this web app are stored on your computer. Because
              of technological reasons this application retrieves all your RSS
              feeds using a small script on our server. Technically, this means
              that all of the feed data will be passed through our server.
              However, none of this data is being stored or in any way analysed.
            </p>
            <p>
              Whenever you visit this application, and whenever the feeds
              refresh, a request is being sent to our server, which results in
              a log file entry being written. This log file entry includes the
              page and time visited. It does not, however, include any
              personally identifiable data. Furthermore, all logs only serve
              debugging processes and are henceforth only analysed in case of
              a server failure, and are permanently deleted after 30 days.
            </p>
          </div>
          <button v-on:click.prevent="saveSettings()">Save</button>
          <button v-on:click.prevent="hideSettings()">Cancel</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { SettingsIcon } from 'vue-feather-icons'

export default {
  name: 'Settings',
  data: function () {
    return {
      showConfig: false
    }
  },
  components: {
    SettingsIcon
  },
  computed: {
  },
  methods: {
    showSettings: function () {
      this.showConfig = true
      // Preset all inputs accordingly
      this.$refs.title.value = this.$store.state.config.title
      this.$refs.background.value = this.$store.state.config.background
    },
    hideSettings: function () {
      this.showConfig = false
    },
    saveSettings: function () {
      const object = {
        // Preset everything and then commit to the saver
        title: this.$refs.title.value,
        background: this.$refs.background.value
      }

      this.$store.commit('saveConfig', object)
      // Finally, hide again
      this.hideSettings()
    }
  }
}
</script>

<style>
div#edit-config {
  position: fixed;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

/* Modals */
div.modal {
  z-index: 99999;
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, .3);
  top: 0;
  left: 0;
}

div.modal-container {
  background-color: rgb(20, 36, 46);
  color: rgb(190, 190, 190);
  position: fixed;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  border-radius: 40px;
  padding: 20px;
}

div.modal-container input {
  font-size: 150%;
  width: 100%;
  margin-bottom: 10px;
}

div.modal-container button {
  font-size: 150%;
  margin: 15px;
}
</style>
