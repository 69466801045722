<template>
  <div id="weather">
    <template v-if="!showInfo">
      <div id="weather-container" class="box-background">
        <div id="weather-info">{{ weatherState }} in {{ weatherLocation }}</div>

        <div id="weather-icon">
          <!-- Snow -->
          <CloudSnowIcon height="36" v-if="['sn'].includes(weatherStateAbbr)" />
          <!-- Drizzle for sleet and hailstorms -->
          <CloudDrizzleIcon height="36" v-if="['sl','h'].includes(weatherStateAbbr)" />
          <!-- Rain for heavy and light rain and showers -->
          <CloudRainIcon height="36" v-if="['hr','lr','s'].includes(weatherStateAbbr)" />
          <!-- Thunderstorm -->
          <CloudLightningIcon height="36" v-if="['t'].includes(weatherStateAbbr)" />
          <!-- Clouds (heavy and light) -->
          <CloudIcon height="36" v-if="['hc','lc'].includes(weatherStateAbbr)" />
          <!-- Clear === Sunny -->
          <SunIcon height="36" v-if="['c'].includes(weatherStateAbbr)" />
        </div>
        <!-- Now the temperature -->
        <div id="weather-temperature">
          {{ getTemp(tempNow) }} °{{ tempUnit }}
        </div>
        <!-- Immediately after, minimum and maximum temperature -->
        <div id="weather-min-max" class="muted">
          {{ getTemp(tempMax) }} °{{ tempUnit }}<br>
          {{ getTemp(tempMin)}} °{{ tempUnit }}
        </div>
        <!-- Imperial/Metric toggle and the info -->
        <div id="weather-system-toggle">
          <small v-bind:class="metricToggleClass" v-on:click.stop="toggleSystem('metric')">°C</small>
          &nbsp;&nbsp;
          <small v-bind:class="impToggleClass" v-on:click.stop="toggleSystem('imperial')">°F</small>
          &nbsp;&nbsp;
          <InfoIcon size="12" class="clickable" v-on:click.stop="toggleInfoPanel"/>
          <br>
          <!-- Finally, the wind -->
          <small class="muted">
            <WindIcon size="12"/>
            {{ windSpeed * lengthFactor }} {{lengthUnit}}/h

            <!-- N, NE, E, SE, S, SW, W, NW -->
            <!-- Cardinal directions -->
            <ArrowUpIcon size="12" v-if="windDir === 'N'"/>
            <ArrowRightIcon size="12" v-if="windDir === 'E'"/>
            <ArrowDownIcon size="12" v-if="windDir === 'S'"/>
            <ArrowLeftIcon size="12" v-if="windDir === 'W'"/>
            <!-- Sub-Cardinal directions -->
            <ArrowUpLeftIcon size="12" v-if="[ 'NNW', 'NW', 'WNW' ].includes(windDir)"/>
            <ArrowDownRightIcon size="12" v-if="[ 'ESE', 'SE', 'SSE' ].includes(windDir)"/>
            <ArrowDownLeftIcon size="12" v-if="[ 'WSW', 'SW', 'SSW' ].includes(windDir)"/>
            <ArrowUpRightIcon size="12" v-if="[ 'NNE', 'NE', 'ENE' ].includes(windDir)"/>
          </small>
        </div>
        <div class="muted">
          <small>Last updated {{ lastUpdatedTime }}</small>
        </div>
      </div>
    </template>

    <!-- Now the info panel -->

    <template v-else>
      <div id="weather-container" class="box-background">
        <small class="muted">
          Data provided by <a href="https://www.metaweather.com/" target="_blank" rel="nofollow">MetaWeather</a>
        </small>&nbsp;
        <small class="muted clickable" v-on:click.stop="toggleInfoPanel">(Close)</small>
        <div v-if="weatherLocation !== city">
          Weather forecast is shown for:<br>
          <strong>{{ weatherLocation }} ({{ weatherLocationType }})</strong><br>
          <small class="muted">
          (Distance from {{ city }}: {{ Math.round(distance / 100 * lengthFactor) / 10 }} {{ lengthUnit }})
        </small>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import axios from 'axios'
import { DateTime } from 'luxon'

import {
  // Weather state icons
  SunIcon,
  CloudIcon,
  CloudSnowIcon,
  CloudRainIcon,
  CloudDrizzleIcon,
  CloudLightningIcon,
  WindIcon,
  // Import arrows
  ArrowDownLeftIcon,
  ArrowDownRightIcon,
  ArrowDownIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowUpLeftIcon,
  ArrowUpRightIcon,
  ArrowUpIcon,
  // Miscellaneous icons
  InfoIcon
} from 'vue-feather-icons'

const SYSTEM_METRIC = 'metric'
const SYSTEM_IMPERIAL = 'imperial'

export default {
  name: 'Weather',
  components: {
    SunIcon,
    CloudIcon,
    CloudSnowIcon,
    CloudRainIcon,
    CloudDrizzleIcon,
    CloudLightningIcon,
    WindIcon,
    ArrowDownLeftIcon,
    ArrowDownRightIcon,
    ArrowDownIcon,
    ArrowLeftIcon,
    ArrowRightIcon,
    ArrowUpLeftIcon,
    ArrowUpRightIcon,
    ArrowUpIcon,
    InfoIcon
  },
  data: function () {
    return {
      city: 'Berlin',
      region: 'Berlin',
      country: 'Germany',
      latitude: '52.516071',
      longitude: '13.376980',
      weatherLocation: 'Berlin', // Can be a different location
      weatherLocationType: 'City',
      distance: 0, // Distance from detected city to forecast city in meters
      // Forecast data
      tempMin: 0,
      tempMax: 0,
      tempNow: 0,
      windDir: '',
      windSpeed: 0, // Mph
      weatherState: '', // e.g. Light Cloud, see list here: https://www.metaweather.com/api/
      weatherStateAbbr: '',
      // System, default metric
      system: SYSTEM_METRIC,
      lastUpdated: DateTime.local(), // Last update info
      showInfo: false // Whether to show the info pane
    }
  },
  mounted: function () {
    // Retrieve initial data ...
    this.updateForecast()

    // ... and set a fifteen minute interval for updating the forecast
    const self = this
    setInterval(function () {
      self.updateForecast()
    }, 1000 * 60 * 15)
  },
  computed: {
    lengthFactor: function () {
      if (this.system === SYSTEM_IMPERIAL) {
        // one mile equals 1.609344 km
        return 0.62
      }
      return 1
    },
    lengthUnit: function () {
      if (this.system === SYSTEM_IMPERIAL) {
        return 'mi'
      }
      return 'km'
    },
    tempUnit: function () {
      if (this.system === SYSTEM_IMPERIAL) {
        return 'F'
      }
      return 'C'
    },
    metricToggleClass: function () {
      if (this.system === SYSTEM_METRIC) {
        return 'clickable active'
      } else {
        return 'clickable'
      }
    },
    impToggleClass: function () {
      if (this.system === SYSTEM_IMPERIAL) {
        return 'clickable active'
      } else {
        return 'clickable'
      }
    },
    lastUpdatedTime: function () {
      return this.lastUpdated.toLocaleString(DateTime.TIME_24_SIMPLE)
    }
  },
  methods: {
    getTemp: function (tempCelsius) {
      if (this.system === SYSTEM_METRIC) {
        return tempCelsius
      } else if (this.system === SYSTEM_IMPERIAL) {
        return tempCelsius * 1.8 + 32 // Convert to Fahrenheit
      }
    },
    toggleInfoPanel: function () {
      this.showInfo = !this.showInfo
    },
    toggleSystem: function (newSystem) {
      this.system = newSystem
    },
    updateForecast: async function () {
      let CORS_PREFIX = window.location.origin

      // Update the last time we've tried reaching the APIs
      this.lastUpdated = DateTime.local()

      if (window.location.hostname === 'localhost') {
        CORS_PREFIX = 'http://localhost:8000'
      }

      // First, retrieve the GeoIP location data
      const locationResponse = await axios.get('https://freegeoip.app/json/')
      const locationData = locationResponse.data
      // console.log(locationData)
      // Update the necessary properties
      this.city = locationData.city
      this.region = locationData.region_name
      this.country = locationData.country_name
      this.latitude = locationData.latitude
      this.longitude = locationData.longitude

      // Now, after we have latitude and longitude (as a reminder, longitude
      // are those lines from North pole to Antarctica, latitude are the
      // horizontal lines. Yes, I'm not a geographer, so let me use my silly
      // analogies!) we can get the weather info

      // First we need a city search. Some cities are not available on metaweather
      // but it thankfully gives us a list of cities including the distance to
      // our original coordinates in meters.
      const loc = `https://www.metaweather.com/api/location/search/?lattlong=${this.latitude},${this.longitude}`
      const citySearch = await axios.get(`${CORS_PREFIX}/cors.php?u=${loc}`)
      // console.log(citySearch)
      const cityData = citySearch.data

      // distance: 140302,
      // title: "Stockholm",
      // location_type: "City",
      // woeid: 906057,
      // latt_long: "59.332169,18.062429"
      this.weatherLocation = cityData[0].title
      this.weatherLocationType = cityData[0].location_type
      this.distance = cityData[0].distance

      // Now retrieve the data
      const woeID = cityData[0].woeid // The ID used to retrieve the actual weather forecast

      // Build the request
      const weatherLoc = `https://www.metaweather.com/api/location/${woeID}/`
      const weatherResponse = await axios.get(`${CORS_PREFIX}/cors.php?u=${weatherLoc}`)
      const weatherData = weatherResponse.data
      const todayForecast = weatherData.consolidated_weather[0] // Offers 5-day forecast, we only need today
      // console.log(todayForecast)

      // We need to convert windspeed from mph to kmh
      // SI-unit is 1mph = 0.44704ms = 1,609.344m/h = 1.609344km/h
      const kmhFactor = 1.609344

      // If we're ever that precise, the temperatures are given in floats :D
      this.tempMin = Math.round(todayForecast.min_temp)
      this.tempMax = Math.round(todayForecast.max_temp)
      this.tempNow = Math.round(todayForecast.the_temp)
      this.weatherState = todayForecast.weather_state_name
      this.weatherStateAbbr = todayForecast.weather_state_abbr
      this.windDir = todayForecast.wind_direction_compass
      this.windSpeed = Math.round(todayForecast.wind_speed * kmhFactor)
    }
  }
}
</script>

<style>
div#weather {
  text-align: center;
  align-self: center;
}

div#weather svg {
  vertical-align: middle;
}

div#weather small {
  font-size: 12px;
}

div#weather .clickable.active {
  text-decoration: underline;
}

div#weather .muted {
  color: #666;
}

/** End general styles */

div#weather div#weather-container {
  padding: 10px;
  display: inline-block;
}

div#weather-icon,
div#weather-min-max,
div#weather-temperature,
div#weather-system-toggle {
  display: inline-block;
  font-size: 36px;
  line-height: 36px;
  padding: 0;
  margin: 0;
}

div#weather-info {
  margin-bottom: 10px;
}

div#weather-min-max {
  text-align: right;
}

div#weather-temperature,
div#weather-system-toggle {
  margin-left: 10px;
}

div#weather-min-max,
div#weather-system-toggle {
  margin-left: 10px;
  font-size: 14px;
  line-height: 14px;
}
</style>
