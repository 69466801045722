<template>
  <div>
    <div class="box-background deadline" v-show="!isEditing">
      <div>
        <p class="message">
          <SettingsIcon size="0.9x" class="clickable" v-on:click.stop="isEditing = !isEditing"/>
          {{ deadline.message }}
        </p>
        <Countdown v-bind:end="deadline.endsOn"/>
      </div>
      <a class="remove-button" v-on:click.stop="remove">&times;</a>
    </div>
    <div v-show="isEditing">
      <!-- Display the editing form -->
      <div ref="new-form" class="box-background">
        <div>
          <input ref="title" type="text" placeholder="Title">
          <!--
            Except Chrome, almost no browser supports datetime-local so we
            have to construct the thing using two inputs ...
          -->
          <div id="deadline-time-input-group">
            <input
              ref="date"
              type="date"
              pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
              placeholder="yyyy-mm-dd"
            >
            <input
              ref="time"
              type="time"
              pattern="[0-9]{2}:[0-9]{2}"
              placeholder="hh:mm"
            >
          </div>
        </div>
        <div>
          <button v-on:click.prevent="saveChanges">Save Changes</button>
          <button v-on:click.prevent="isEditing = !isEditing">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Countdown from './Countdown'
import { SettingsIcon } from 'vue-feather-icons'

export default {
  name: 'Deadline',
  props: ['deadline'],
  data: function () {
    return {
      isEditing: false
    }
  },
  watch: {
    isEditing: function () {
      if (this.isEditing) {
        let date = new Date(this.deadline.endsOn)
        date = date.toISOString().split('T')
        this.$refs.title.value = this.deadline.message
        this.$refs.date.value = date[0]
        this.$refs.time.value = date[1].substr(0, 5) // Remove seconds and milliseconds
      }
    }
  },
  components: {
    Countdown,
    SettingsIcon
  },
  methods: {
    remove: function () {
      this.$emit('remove')
    },
    saveChanges: function () {
      const date = this.$refs.date
      const time = this.$refs.time
      const title = this.$refs.title

      if (title.value.trim() === '') {
        title.classList.add('error')
        return
      }

      if (!/[0-9]{4}-[0-9]{2}-[0-9]{2}/.test(date.value)) {
        date.classList.add('error')
        return
      }

      if (!/[0-9]{2}:[0-9]{2}/.test(time.value)) {
        time.classList.add('error')
        return
      }

      const datetime = new Date(date.value + ' ' + time.value)
      this.$emit('change', {
        // NOTE: These must be the correct names, they are just passed on.
        message: title.value,
        endsOn: datetime.getTime()
      })
      this.isEditing = false
    }
  }
}
</script>

<style>
div.deadline svg {
  vertical-align: middle;
}
</style>
