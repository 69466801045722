<template>
  <div id="news">
    <h1>
      News
      <SettingsIcon class="clickable" v-on:click.stop="toggleSettings"/>
    </h1>
    <div id="news-container" class="box-background">
      <template v-if="showSettings">
        <div>
          <div>
            <input ref="add-new" type="text" placeholder="Link to any valid RSS feed URL">
          </div>
          <div>
            <button v-on:click.prevent="addFeed">Add</button>
          </div>
        </div>
        <div v-bind:key="key" v-for="(feed, key) of feeds" class="feed-list-item">
          <a v-bind:href="feed" target="_blank">{{ feed }}</a>
          <a class="remove-button" v-on:click.stop="removeFeed(feed)">&times;</a>
        </div>
      </template>
      <template v-else-if="items.length > 0">
        <div id="news-navigator">
          <!-- Show the counter -->
          <div>
            <ChevronLeftIcon class="clickable" v-on:click.prevent="decrement()"/>
          </div>
          <div v-bind:key="idx" v-for="(n, idx) in items.length" v-on:click.stop="setCurrent(idx)">
            <div v-if="idx === currentIndex" class="navigator-bullet active"></div>
            <div v-else class="navigator-bullet"></div>
          </div>
          <div>
            <ChevronRightIcon class="clickable" v-on:click.prevent="increment()"/>
          </div>
          <!-- Start/Stop toggle -->
          <div v-on:click.prevent="autoToggle = !autoToggle">
            <PauseCircleIcon class="clickable" v-if="autoToggle"/>
            <PlayCircleIcon class="clickable" v-else/>
          </div>
        </div>
        <!-- Display the current news item -->
        <div>
          <p class="news-feed-meta">
            {{ items[currentIndex].feed }}<br>
            ({{ items[currentIndex].printableDate }})
          </p>
          <h3 class="news-feed-title">
              <a v-bind:href="items[currentIndex].link" target="_blank">{{ items[currentIndex].title }}</a>
          </h3>
          <p class="news-feed-description">{{ items[currentIndex].description }}</p>
        </div>
      </template>
      <template v-else>
        <p>No feed items available</p>
        <p><a href="#" v-on:click.stop.prevent="fetchFeedData()">Refetch now</a></p>
      </template>
    </div>
  </div>
</template>

<script>
import {
  SettingsIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
  PauseCircleIcon,
  PlayCircleIcon
} from 'vue-feather-icons'
import axios from 'axios'
import { DateTime } from 'luxon'

export default {
  name: 'News',
  components: {
    SettingsIcon,
    ChevronRightIcon,
    ChevronLeftIcon,
    PauseCircleIcon,
    PlayCircleIcon
  },
  data: function () {
    return {
      feedItems: [],
      autoToggle: true,
      currentIndex: 0,
      showSettings: false,
      now: Date.now()
    }
  },
  created: function () {
    // Initiate the different timer intervals
    var self = this

    // Re-fetch the feeds every 15 minutes
    setInterval(function () {
      self.fetchFeedData()
    }, 1000 * 60 * 15)

    setInterval(function () {
      // Toggle through the news items every 10 seconds
      if (self.autoToggle) {
        self.increment()
      }
    }, 1000 * 10)
  },
  watch: {
    feeds: function () {
      this.fetchFeedData()
    }
  },
  mounted: function () {
  },
  computed: {
    feeds: function () {
      return this.$store.state.rssFeeds
    },
    items: function () {
      // This prop returns all items interleaved from all feeds in one array
      const items = this.feedItems

      // Add a new printableDate element
      items.map(item => {
        item.printableDate = item.pubDate.toRelative()
      })

      // Sort the items based on date
      items.sort((a, b) => {
        return b.pubDate.diff(a.pubDate, 'seconds').toObject().seconds
      })

      // Return the three most recent items
      // console.log(items.slice(0, 10).map(elem => elem.title).join('\n'))
      return items.slice(0, 10)
    } // END: items
  },
  methods: {
    toggleSettings: function () {
      this.showSettings = !this.showSettings
    },
    addFeed: function () {
      this.$store.commit('addFeed', this.$refs['add-new'].value)
      this.$refs['add-new'].value = ''
    },
    removeFeed: function (feed) {
      this.$store.commit('removeFeed', feed)
    },
    fetchFeedData: function () {
      this.feedItems = [] // Reset and prepare for a new batch
      let BASE_URL = window.location.origin

      if (window.location.hostname === 'localhost') {
        // If we're in dev mode, the PHP server runs on port 8000, while
        // the Vue CLI runs on port 8080, so we have to rewrite that.
        BASE_URL = 'http://localhost:8000'
      }

      for (const feed of this.feeds) {
        // Get the feed data using our utility script which spits out
        // JSON conveniently which we can use immediately
        axios.get(`${BASE_URL}/rss.php?u=${feed}`)
          .then(response => {
            // The server returns all data in a well-formed format which is
            // protocol agnostic.
            const feedData = response.data

            for (const item of feedData.items) {
              const date = new Date(item.pubDate)
              const ms = date.getTime()
              let substrEnd = item.description.length

              if (item.description.length > 300) {
                // Shorten too long descriptions
                let lastIndex = item.description.substr(0, 300).lastIndexOf('.') + 1
                if (lastIndex === 0) {
                  lastIndex = item.description.substr(0, 300).lastIndexOf(' ') + 1
                }
                substrEnd = lastIndex
              }

              this.feedItems.push({
                title: item.title,
                link: item.link,
                feed: feedData.title,
                pubDate: DateTime.fromMillis(ms), // Enable JS manipulation
                description: item.description.substr(0, substrEnd)
              })
            }
          })
          .catch(err => {
            console.error(`Could not retrieve feed ${feed}`)
            console.error(err)
          })
      }
    }, // END fetch data
    setCurrent: function (idx) {
      if (idx >= this.items.length) {
        this.currentIndex = this.items.length - 1
      } else if (idx < 0) {
        this.currentIndex = 0
      } else {
        this.currentIndex = idx
      }
    },
    increment: function () {
      this.currentIndex++
      if (this.currentIndex >= this.items.length) {
        this.currentIndex = 0
      }
    },
    decrement: function () {
      this.currentIndex--
      if (this.currentIndex < 0 && this.items.length > 0) {
        this.currentIndex = this.items.length - 1
      } else if (this.currentIndex < 0) {
        this.currentIndex = 0
      }
    }
  }
}
</script>

<style>
div#news-container {
  overflow-y: auto;
}

div#news-container a.remove-button {
  font-size: inherit;
}

.news-feed-meta {
  margin-bottom: 0px;
  color: #999;
}

div.feed-list-item {
  display: flex;
  justify-content: space-between;
}

div#news-navigator {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

div#news-navigator svg {
  vertical-align: middle;
}

.navigator-bullet {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.4s background-color ease;
  border: 1px solid white;
}

.navigator-bullet:hover, .navigator-bullet.active {
  background-color: white;
}
</style>
