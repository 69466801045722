<template>
  <div id="notes">
    <h1>Notes
      <template v-if="isSaving">
        <LoaderIcon/>
      </template>
      <template v-else>
        <CheckIcon/>
      </template>
    </h1>
    <div class="box-background">
      <Codemirror ref="cm" v-bind:options="cmOptions" v-bind:value="noteContents" v-on:input="beginSaveTimeout"></Codemirror>
    </div>
  </div>
</template>

<script>
import { codemirror as Codemirror } from 'vue-codemirror'
import { LoaderIcon, CheckIcon } from 'vue-feather-icons'
// Markdown mode
import 'codemirror/mode/gfm/gfm.js'
// Base CSS
import 'codemirror/lib/codemirror.css'

// We need better scrollbars
import 'codemirror/addon/scroll/simplescrollbars.js'
// Depends upon these styles, which we can style below
import 'codemirror/addon/scroll/simplescrollbars.css'

export default {
  name: 'Notes',
  components: {
    Codemirror,
    CheckIcon,
    LoaderIcon
  },
  data: function () {
    return {
      saveTimeout: null,
      isSaving: false,
      cmOptions: {
        // codemirror options
        tabSize: 4,
        mode: 'text/markdown',
        theme: 'startpage',
        scrollbarStyle: 'overlay',
        lineNumbers: false,
        lineWrapping: false
      }
    }
  },
  computed: {
    noteContents: function () {
      return this.$store.state.noteContents
    }
  },
  methods: {
    beginSaveTimeout: function () {
      if (this.saveTimeout !== null) {
        clearTimeout(this.saveTimeout)
        this.saveTimeout = null
      }

      this.isSaving = true
      this.saveTimeout = setTimeout(() => {
        this.save()
      }, 500) // Attempt to save at least every half second
    },
    save: function () {
      this.saveTimeout = null
      this.$store.commit('saveNotes', this.$refs.cm.content)
      this.isSaving = false
    }
  }
}
</script>

<style>
.CodeMirror.cm-s-startpage {
  background-color: transparent;
  color: #eee;
  max-width: 35vw; /* Constrain the max width TODO: adapt for mobile */
}

/* Elements */
.CodeMirror.cm-s-startpage .cm-strong,
.CodeMirror.cm-s-startpage .cm-em {
  color: #faa;
}

.CodeMirror.cm-s-startpage .cm-header-1,
.CodeMirror.cm-s-startpage .cm-header-2,
.CodeMirror.cm-s-startpage .cm-header-3,
.CodeMirror.cm-s-startpage .cm-header-4,
.CodeMirror.cm-s-startpage .cm-header-5,
.CodeMirror.cm-s-startpage .cm-header-6 {
  color: #ffa;
}

.CodeMirror.cm-s-startpage .CodeMirror-cursor {
  border-left: 1px solid white;
}

/* Focused selection */
.CodeMirror.cm-s-startpage.CodeMirror-focused .CodeMirror-selected {
  background-color: rgba(10, 104, 10, 0.8);
}

/* Unfocused selection */
.CodeMirror.cm-s-startpage .CodeMirror-selected {
  background-color: rgba(80, 104, 80, 0.8);
}
</style>
