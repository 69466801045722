<template>
  <svg v-bind:height="size" v-bind:width="size">
    <!-- Outer circle -->
    <circle
      class="rotate-forward"
      v-bind:stroke-dasharray="arrayValues"
      v-bind:cx="size/2" v-bind:cy="size/2" v-bind:r="size/2"
      fill="none"
      v-bind:stroke="color"
      stroke-width="1"
    />

    <!-- Inner circle -->
    <circle
      class="rotate-reverse"
      v-bind:stroke-dasharray="arrayValues"
      v-bind:cx="size/2" v-bind:cy="size/2" v-bind:r="size/2 - 4"
      fill="none"
      v-bind:stroke="color"
      stroke-width="1"
    />
  </svg>
</template>

<script>
export default {
  name: 'RotatingCircle',
  props: [
    'size',
    'color'
  ],
  computed: {
    arrayValues: function () {
      const long = this.size / 5
      const mid = this.size / 10
      const short = this.size / 20

      return `${long},${mid},${short},${short},${short},${mid}` // '20,10,5,5,5,10'
    }
  }
}
</script>

<style>
.rotate-forward {
  transform-origin: center;
  animation: rotation-forward 10s linear infinite;
}

.rotate-reverse {
  transform-origin: center;
  animation: rotation-reverse 20s linear infinite;
}

@keyframes rotation-forward {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotation-reverse {
  100% {
    transform: rotate(-360deg);
  }
}
</style>
