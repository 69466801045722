import Vuex from 'vuex'

export default function createStore () {
  return new Vuex.Store({
    state: {
      config: {
        title: 'Startpage',
        background: 'https://startpage.zettlr.com/zettlr_neon.png'
      },
      noteContents: 'Add your notes here ...',
      rssFeeds: [
        'https://zettlr.com/feed'
      ],
      deadlines: []
    },
    mutations: {
      init: function (state) {
        // Init from local storage
        const storage = localStorage.getItem('startpage-config')
        if (storage === null) {
          localStorage.setItem('startpage-config', JSON.stringify(state.config))
        } else {
          // Override using the localStorage
          this.commit('saveConfig', JSON.parse(storage))
        }

        // Same for notes
        const notes = localStorage.getItem('startpage-notes')
        if (notes === null) {
          localStorage.setItem('startpage-notes', state.noteContents)
        } else {
          this.commit('saveNotes', notes)
        }

        // And feeds
        const feeds = localStorage.getItem('startpage-feeds')
        if (feeds === null) {
          localStorage.setItem('startpage-feeds', JSON.stringify(state.rssFeeds))
        } else {
          this.commit('setFeeds', JSON.parse(feeds))
        }

        // Deadlines
        const deadlines = localStorage.getItem('startpage-deadlines')
        if (deadlines === null) {
          localStorage.setItem('startpage-deadlines', JSON.stringify(state.deadlines))
        } else {
          this.commit('setDeadlines', JSON.parse(deadlines))
        }
      },
      saveConfig: function (state, newSettings) {
        state.config = newSettings
        // Immediately persist in localStorage
        localStorage.setItem('startpage-config', JSON.stringify(state.config))
      },
      saveNotes: function (state, noteContents) {
        state.noteContents = noteContents
        localStorage.setItem('startpage-notes', noteContents)
      },
      // Feeds
      addFeed: function (state, feed) {
        state.rssFeeds.push(feed)
        localStorage.setItem('startpage-feeds', JSON.stringify(state.rssFeeds))
      },
      setFeeds: function (state, feeds) {
        state.rssFeeds = feeds
      },
      removeFeed: function (state, feed) {
        state.rssFeeds.splice(state.rssFeeds.indexOf(feed), 1)
        localStorage.setItem('startpage-feeds', JSON.stringify(state.rssFeeds))
      },
      // Deadlines
      addDeadline: function (state, deadline) {
        state.deadlines.push(deadline)
        localStorage.setItem('startpage-deadlines', JSON.stringify(state.deadlines))
      },
      removeDeadline: function (state, key) {
        state.deadlines.splice(key, 1)
        localStorage.setItem('startpage-deadlines', JSON.stringify(state.deadlines))
      },
      setDeadlines: function (state, deadlines) {
        state.deadlines = deadlines
      }
    },
    getters: {},
    actions: {}
  })
}
