<template>
  <div id="greeter">
    <p id="message">
      {{ message }}

      <span v-if="timeOfDay === 'morning'">
        <SunriseIcon size="20" />
      </span>
      <span v-else-if="timeOfDay === 'day'">
        <SunIcon size="20"/>
      </span>
      <span v-else-if="timeOfDay === 'afternoon'">
        <SunIcon size="20" />
      </span>
      <span v-else-if="timeOfDay === 'evening'">
        <SunsetIcon size="20"/>
      </span>
      <span v-else>
        <MoonIcon size="20"/>
      </span>
    </p>
    <p id="time">
      {{ formattedHour }}
      <span id="second-indicator">
        <!-- "Blink" every second -->
        <span v-show="this.now.second % 2 === 0">:</span>
      </span>
      {{ formattedMinute }}
    </p>
    <WeekStrip/>
  </div>
</template>

<script>
import {
  SunIcon,
  SunriseIcon,
  SunsetIcon,
  MoonIcon
} from 'vue-feather-icons'

import WeekStrip from './WeekStrip'

import { DateTime } from 'luxon'

export default {
  name: 'Greeter',
  data: function () {
    return {
      now: DateTime.local()
    }
  },
  created () {
    var self = this
    this.$root.$on('pulse', function () {
      self.now = DateTime.local()
    })
  },
  components: {
    SunIcon,
    MoonIcon,
    SunriseIcon,
    SunsetIcon,
    WeekStrip
  },
  computed: {
    formattedHour: function () {
      if (this.now.hour < 10) {
        return `0${this.now.hour}`
      }
      return this.now.hour
    },
    formattedMinute: function () {
      if (this.now.minute < 10) {
        return `0${this.now.minute}`
      }
      return this.now.minute
    },
    timeOfDay: function () {
      // Returns "morning", "day", "afternoon", "evening", and "night"
      const hour = this.now.hour
      if (hour < 4 || hour >= 22) {
        return 'night'
      } else if (hour >= 4 && hour < 9) {
        return 'morning'
      } else if (hour >= 10 && hour < 12) {
        return 'day'
      } else if (hour >= 12 && hour < 18) {
        return 'afternoon'
      } else if (hour >= 18 && hour < 22) {
        return 'evening'
      } else {
        return 'day'
      }
    },
    message: function () {
      const timeOfDay = this.timeOfDay
      if (timeOfDay === 'night') {
        return 'Good night'
      } else if (timeOfDay === 'morning') {
        return 'Good morning'
      } else if (timeOfDay === 'day') {
        return 'Good day'
      } else if (timeOfDay === 'afternoon') {
        return 'Good afternoon'
      } else if (timeOfDay === 'evening') {
        return 'Good evening'
      } else {
        return 'If you can see this, I was too stupid to compute the time of day.'
      }
    }
  }
}
</script>

<style>
div#greeter {
  text-align: center;
  margin-bottom: 10px;
  font-size: 24px; /** We need an absolute size */
}

p#time {
  font-size: 48px;
  margin: 0;
}

span#second-indicator {
  display: inline-block;
  width: 10px;
}
</style>
