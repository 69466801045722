<template>
  <div id="deadlines">
    <h1>Deadlines <a class="clickable" v-on:click="showForm">&plus;</a></h1>
    <div ref="new-form" class="box-background" v-if="displayAddForm">
      <div>
        <input ref="deadline-title" type="text" placeholder="Title">
        <!--
          Except Chrome, almost no browser supports datetime-local so we
          have to construct the thing using two inputs ...
        -->
        <div id="deadline-time-input-group">
          <input
            ref="deadline-date"
            type="date"
            pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
            placeholder="YYYY-MM-DD"
          >
          <input
            ref="deadline-time"
            type="time"
            pattern="[0-9]{2}:[0-9]{2}"
            placeholder="HH:MM"
          >
        </div>
      </div>
      <div>
        <button v-on:click.prevent="addDeadline">Add</button>
        <button v-on:click.prevent="hideForm">Cancel</button>
      </div>
    </div>
    <template v-for="(deadline, key) of deadlines">
      <Deadline
        v-bind:key="key"
        v-bind:deadline="deadline"
        v-on:remove="removeDeadline(key)"
        v-on:change="changeDeadline(key, $event)"
      />
    </template>
    <div class="box-background" v-if="deadlines.length === 0">
      <p class="message">You don't have any deadlines.</p>
    </div>
  </div>
</template>

<script>
import Deadline from './Deadline'

export default {
  name: 'Deadlines',
  data: function () {
    return {
      displayAddForm: false
    }
  },
  components: {
    Deadline
  },
  computed: {
    deadlines: function () {
      const deadlines = this.$store.state.deadlines
      deadlines.sort((a, b) => {
        // Sort with soonest expiring deadlines first
        return a.endsOn - b.endsOn
      })
      return deadlines
    }
  },
  methods: {
    removeDeadline: function (key) {
      this.$store.commit('removeDeadline', key)
    },
    addDeadline: function () {
      // Adds a deadline
      const title = this.$refs['deadline-title']
      const date = this.$refs['deadline-date']
      const time = this.$refs['deadline-time']

      if (title.value.trim() === '') {
        title.classList.add('error')
        return
      }

      if (!/[0-9]{4}-[0-9]{2}-[0-9]{2}/.test(date.value)) {
        date.classList.add('error')
        return
      }

      if (!/[0-9]{2}:[0-9]{2}/.test(time.value)) {
        time.classList.add('error')
        return
      }

      const datetime = new Date(date.value + ' ' + time.value)

      this.$store.commit('addDeadline', {
        endsOn: datetime.getTime(),
        message: title.value
      })

      title.value = ''
      time.value = ''
      date.value = ''
      title.classList.remove('error')
      time.classList.remove('error')
      date.classList.remove('error')
      this.displayAddForm = false
    },
    changeDeadline: function (key, event) {
      // $event is a magic variable which contains all properties we need
      this.$store.commit('removeDeadline', key)
      this.$store.commit('addDeadline', event)
    },
    showForm: function () {
      this.displayAddForm = true
    },
    hideForm: function () {
      this.displayAddForm = false
    }
  }
}
</script>

<style>
div#deadlines .deadline {
  display: flex;
  justify-content: space-between;
}

div#deadlines .message {
  margin: 0;
  font-weight: bold;
}

#deadline-time-input-group {
  display: flex;
  justify-content: space-between;
}

#deadline-time-input-group input {
  flex: 1 1 0px;
}
</style>
