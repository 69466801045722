<template>
  <div id="week-strip">
    <!-- Display a week-strip and indicate the current day -->
    <div class="greeter-weekday">
      Mon
      <div class="circle-container" v-if="weekday === 1">
        <RotatingCircle size="35" v-bind:color="circleColor" />
      </div>
    </div>
    <div class="greeter-weekday">
      Tue
      <div class="circle-container" v-if="weekday === 2">
        <RotatingCircle size="35" v-bind:color="circleColor" />
      </div>
    </div>
    <div class="greeter-weekday">
      Wed
      <div class="circle-container" v-if="weekday === 3">
        <RotatingCircle size="35" v-bind:color="circleColor" />
      </div>
    </div>
    <div class="greeter-weekday">
      Thu
      <div class="circle-container" v-if="weekday === 4">
        <RotatingCircle size="35" v-bind:color="circleColor" />
      </div>
    </div>
    <div class="greeter-weekday">
      Fri
      <div class="circle-container" v-if="weekday === 5">
        <RotatingCircle size="35" v-bind:color="circleColor" />
      </div>
    </div>
    <div class="greeter-weekday weekend">
      Sat
      <div class="circle-container" v-if="weekday === 6">
        <RotatingCircle size="35" v-bind:color="circleColor" />
      </div>
    </div>
    <div class="greeter-weekday weekend">
      Sun
      <div class="circle-container" v-if="weekday === 7">
        <RotatingCircle size="35" v-bind:color="circleColor" />
      </div>
    </div>
  </div>
</template>

<script>
import RotatingCircle from '../assets/RotatingCircle'

export default {
  name: 'WeekStrip',
  components: {
    RotatingCircle
  },
  data: function () {
    return {
      now: new Date(),
      colorPhase: 0.0
    }
  },
  created () {
    var self = this
    setInterval(function () {
      // Update the date
      self.now = new Date()

      // Update the colorphase
      self.colorPhase += 0.1
      if (self.colorPhase >= 32.0) {
        self.colorPhase = 0.0
      }
    }, 1000)
  },
  computed: {
    weekday: function () {
      let dayNumber = this.now.getDay()
      // Javascript's date is broken. Period.
      if (dayNumber === 0) {
        dayNumber = 7
      }
      return dayNumber
    },
    circleColor: function () {
      /**
       * "As you can see I am using the values 2 and 4 to change the alignment
       * (or phase) of the green and blue sine waves. I chose 2 and 4 because
       * they nearly divide the range of the sine wave (2π or 6.2) into three
       * equal parts, which puts each sine wave approximately 1/3rd of a cycle,
       * or 120°, out of phase."
       *
       * He's shifting 32 colors, so we need to update the frequency, which
       * we'll be doing in our interval.
       *
       * Source of this: https://krazydad.com/tutorials/makecolors.php (thanks!)
       */
      const frequency = 0.3
      const red = Math.sin(frequency * this.colorPhase + 0) * 127 + 128
      const green = Math.sin(frequency * this.colorPhase + 2) * 127 + 128
      const blue = Math.sin(frequency * this.colorPhase + 4) * 127 + 128
      return `rgb(${red}, ${green}, ${blue})`
    }
  }
}
</script>

<style>
div.greeter-weekday {
  display: inline-block;
  position: relative;
  font-size: 10px;
  text-align: center;
  vertical-align: middle;
  /** Sizes */
  line-height: 35px;
  width: 35px;
  height: 35px;
  cursor: default;
}

div.greeter-weekday.weekend {
  color: rgb(214, 74, 191);
}

div.greeter-weekday div.circle-container {
  position: absolute;
  left: 0;
  top: 0;
}
</style>
