<template>
  <div class="countdown" v-bind:style="getStyle">
    {{ countdown }}
  </div>
</template>

<script>
import { DateTime } from 'luxon'

export default {
  name: 'Countdown',
  props: ['end'],
  data: function () {
    return {
      now: DateTime.local(),
      // One-time parsing of the ending time
      endsOn: DateTime.fromMillis(this.end)
    }
  },
  created () {
    // Initiate the timer
    var self = this
    this.$root.$on('pulse', function () {
      self.now = DateTime.local()
    })
  },
  computed: {
    countdown: function () {
      // The diff function conveniently returns a duration object
      const duration = this.endsOn.diff(this.now)
      if (duration.as('seconds') <= 0) {
        // We are done!
        return 'Countdown has expired.'
      }

      // Escaping in luxon is done using single quotes, lol
      if (duration.as('days') > 1) {
        return duration.toFormat('d \'days\', hh:mm:ss')
      } else if (duration.as('days') === 1) {
        return duration.toFormat('d \'day\', hh:mm:ss')
      } else {
        return duration.toFormat('hh:mm:ss')
      }
    },
    getStyle: function () {
      const duration = this.endsOn.diff(this.now)
      if (duration.as('minutes') < 0) {
        return 'color: red'
      } else if (duration.as('hours') < 1) {
        return 'color: rgb(255, 150, 150)'
      } else if (duration.as('days') < 1) {
        return 'color: rgb(255, 200, 100)'
      } else {
        return ''
      }
    }
  }
}
</script>
