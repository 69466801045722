import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'
import createStore from './store'

// Indicate that we would like to use a vuex store
Vue.use(Vuex)

Vue.config.productionTip = false

// Now set up the app, finally
new Vue({
  render: h => h(App),
  mounted: function () {
    this.$store.commit('init')
  },
  store: createStore()
}).$mount('#app')
