<template>
  <div>
    <div id="calendar-wrapper" class="box-background">
      <div id="calendar-heading">{{ now.monthLong }} {{ now.year }}</div>
      <div id="calendar">
        <!-- Display a week-strip and indicate the current day -->
        <div class="weekday">Mon</div>
        <div class="weekday">Tue</div>
        <div class="weekday">Wed</div>
        <div class="weekday">Thu</div>
        <div class="weekday">Fri</div>
        <div class="weekday weekend">Sat</div>
        <div class="weekday weekend">Sun</div>

        <!-- Now, render the padding -->
        <div class="weekday" v-bind:key="num - 7" v-for="num in paddingMonthBegin">
          <!--
            "What's that weird key thing you do there?" Well, vue requires a key,
            irrespective of the content. And it will throw hundreds of errors if
            any key from this ONE v-for are a duplicate of the NEXT v-for. Again,
            JavaScript is broken, and so are some parts of its ecosystem.
          -->
        </div>
        <!-- Afterwards, output all days. Everything will be aligned perfectly due to the grid -->
        <div v-bind:key="key" v-bind:class="getClass(day)" v-for="(day, key) of now.daysInMonth">
          {{ day }}
          <div class="circle-container" v-if="day === now.day">
            <RotatingCircle size="35" v-bind:color="circleColor" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { DateTime } from 'luxon'
import RotatingCircle from './assets/RotatingCircle'

export default {
  name: 'Calendar',
  components: {
    RotatingCircle
  },
  data: function () {
    return {
      // API docs: https://moment.github.io/luxon/docs/class/src/datetime.js~DateTime.html
      now: DateTime.local(),
      colorPhase: 0.0
    }
  },
  created: function () {
    const self = this
    this.$root.$on('pulse', function () {
      self.now = DateTime.local()

      // Update the colorphase
      self.colorPhase += 0.1
      if (self.colorPhase >= 32.0) {
        self.colorPhase = 0.0
      }
    })
  },
  watch: {
    deadlines: function () {
      // this.applyDeadlines()
    }
  },
  computed: {
    // We need a computed property to watch
    deadlines: function () {
      return this.$store.state.deadlines
    },
    paddingMonthBegin: function () {
      const beginningOfMonth = this.now.startOf('month')
      // If the first day is 1, we need 0 padding, if it's 7 (Sunday), we need six padding
      return beginningOfMonth.weekday - 1
    },
    circleColor: function () {
      const frequency = 0.3
      const red = Math.sin(frequency * this.colorPhase + 0) * 127 + 128
      const green = Math.sin(frequency * this.colorPhase + 2) * 127 + 128
      const blue = Math.sin(frequency * this.colorPhase + 4) * 127 + 128
      return `rgb(${red}, ${green}, ${blue})`
    }
  },
  methods: {
    getClass: function (day) {
      const classList = ['weekday']
      const dayDate = this.now.set({ day: day })
      if (dayDate.weekday > 5) {
        classList.push('weekend')
      }

      if (this.hasDeadline(dayDate)) {
        classList.push('has-deadline')
      }

      return classList.join(' ')
    },
    // Returns true if there is a deadline that ends on the given day
    hasDeadline: function (dayDate) {
      return this.deadlines.find((deadline) => {
        return DateTime.fromMillis(deadline.endsOn).hasSame(dayDate, 'day')
      }) !== undefined
    }
  }
}
</script>

<style>
div#calendar-grid-item {
  align-self: center;
  text-align: center;
}

div#calendar-wrapper {
  display: inline-block;
}

div#calendar {
  display: inline-grid;
  /* We have seven days ... */
  grid-template-columns: repeat(7, 25px);
  /* ... and at most 6 partial weeks (+ 1 row week days) */
  grid-template-rows: repeat(7, 25px);
}

div#calendar .circle-container {
  position: absolute;
  top: -5px;
  left: -5px;
}

div#calendar div.weekday {
  position: relative;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  font-size: 10px;
  border-radius: 25px;
}

div#calendar div.weekday.weekend {
  color: rgb(214, 74, 191);
}

div#calendar div.weekday.has-deadline {
  background-color: rgba(255, 0, 0, .5);
}
</style>
