<template>
  <div id="app">
    <Weather/>
    <!-- We need to wrap title and greeter in a div for flex to look good -->
    <div id="welcome-container">
      <h1 id="app-title">{{ pageTitle }}</h1>
      <Greeter/>
    </div>
    <!-- Another div for the calendar -->
    <Calendar/>

    <!-- Now follow the components -->
    <div id="component-left">
      <Notes/>
    </div>
    <div id="component-center">
      <News/>
    </div>
    <div id="component-right">
      <Deadlines/>
    </div>
    <!-- Settings go way down b/c of the modal -->
    <Settings/>
  </div>
</template>

<script>
import Greeter from './components/greeter/Greeter'
import Calendar from './components/Calendar'
import Settings from './components/Settings'
import Notes from './components/Notes'
import News from './components/News'
import Deadlines from './components/deadlines/Deadlines'
import Weather from './components/Weather'

// Import the global application styles
import './app.scss'

export default {
  name: 'App',
  components: {
    Greeter,
    Settings,
    Notes,
    Deadlines,
    News,
    Weather,
    Calendar
  },
  watch: {
    pageBackground: function () {
      this.setPageBackground()
    },
    pageTitle: function () {
      this.setTitle()
    }
  },
  mounted: function () {
    this.setPageBackground()
    this.setTitle()

    const self = this
    // Begin an interval to update/advance the clock every second. This
    // component will emit a pulse event every second, to which child components
    // can listen via this.$root.$on('pulse', callback). This will update the
    // component display at precisely the same time, removing any visual lag
    // between different component update times.
    setInterval(function () {
      self.$root.$emit('pulse')
    }, 1000)
  },
  computed: {
    pageTitle: function () {
      return this.$store.state.config.title
    },
    pageBackground: function () {
      return this.$store.state.config.background
    }
  },
  methods: {
    setPageBackground () {
      document.body.style.backgroundImage = `url('${this.pageBackground}')`
    },
    setTitle () {
      document.title = this.pageTitle
    }
  }
}
</script>

<style lang="scss">

$grid-gap: 15px;

div#app {
  background-color: rgba(0, 0, 0, .5);
  color: #eee;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 2fr;
  align-content: stretch; /** Stretch vertically */
  column-gap: $grid-gap;
  // row-gap: $grid-gap;
  grid-template-areas:
    "weather welcome calendar"
    "component-left component-center component-right";
}

div#welcome-container {
  grid-area: welcome;
}

div#component-left {
  grid-area: component-left;
}

div#component-center {
  grid-area: component-center;
}

div#component-right {
  grid-area: component-right;
}

div#app h1 {
  margin: 15px;
}

h1#app-title {
  text-align: center;
}
</style>
